import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PowerView from '../views/PowerView.vue'
import ColorView from '../views/ColorView.vue'
import CalmView from '../views/CalmView.vue'

const routes = [
  
  {
    path: '/powerful',
    name: 'powerful',
    component: PowerView
  },
  {
    path: '/colorful',
    name: 'colorful',
    component: ColorView
  },
  {
    path: '/calm',
    name: 'calm',
    component: CalmView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash      
      }    
    }  
  }
})

export default router
