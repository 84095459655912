<template>
  <div id="jumbo" class="container-fluid">
  </div>
  <div id="art" class="container">
    <h1>art.</h1>
    <div class="row justify-content-center" style="margin-top: 30px;">
      <div class="col-12 col-md-4" >
        <router-link to="/powerful"> 
          <img src="../assets/Energy_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal5" style="width: 100%; cursor: pointer;">
          <p style="margin-top: 10px;" class="grouptext">Powerful Series</p>
        </router-link>
      </div>
      
      <div class="col-12 col-md-4">
        <router-link to="/colorful"> 
          <img src="../assets/Beauty_of_Nature_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal6" style="width: 100%; cursor: pointer;">
          <p style="margin-top: 10px;" class="grouptext">Colorful Series</p>
        </router-link>
      </div>
      <div class="col-12 col-md-4">
        <router-link to="/calm"> 
          <img src="../assets/Blue_Ocean_quadrat.jpg" class="img-fluid" alt="..." data-bs-toggle="modal" data-bs-target="#exampleModal7" style="width: 100%; cursor: pointer;">
          <p style="margin-top: 10px;" class="grouptext">Calm Series</p>
        </router-link>
        </div>
    </div>         
  </div>

  <div id="about" class="container-fluid" style="background-color: #cacaca;">
    <div class="container" style="padding-top: 20px;padding-bottom: 50px;">
      <h1>about.</h1>    
      <div class="row justify-content-center" style="margin-top: 30px;">
        <div class="col-12 col-md-4">
          <img src="../assets/Moni_normal.62634c1c.jpg" class="img-fluid" alt="...">
        </div>
        <div v-if="is_german" class="col-12 col-md-4" style="text-align: justify; text-justify: inter-word;">
          <p>Monika-Theresia Wesseli ist eine Künstlerin aus der Nähe von München.</p>

          <p>Obwohl sie eigentlich Apothekerin ist, war sie seit jeher begeistert von Kunst. Weil ihre Eltern große Kunstliebhaber sind, besuchte sie seit ihrer Kindheit viele Ausstellungen - und damit ihr nicht langweilig wurde, war der Skizzenblock immer dabei. Von allen Orten in Europa und den USA, die sie besucht hat, sind ihr die Fondation Maeght und die Art Basel besonders ans Herz gewachsen. Inspiriert von Künstlern wie Cy Twombly, Emil Schumacher oder Gerhard Richter malt sie hauptsächlich mit Acryl auf Leinwand.
          
         </p>
        </div>
        
        <div v-else class="col-12 col-md-4" style="text-align: justify; text-justify: inter-word;">
          <p>Monika-Theresia Wesseli is a German artist based near Munich.</p>

          <p>As a pharmacist by profession she has been into art all life long. Since her parents are huge artlovers, she has visited many exhibitions throughout her childhood. Her parents used to carry a sketchbook for her into every museum or exhibtion so that she could paint her impressions and wasn’t too bored. She visited many places in Europe and the US, but Fondation Maeght and Art Basel have always been her favourite ones. Inspired by artists like Cy Twombly, Emil Schumacher or Gerhard Richter she loves to paint primarily with acryl on canvas.</p>
        </div>
        
      </div>
    </div>
  </div>
  <div>
    <div id="contact" class="container">
    <h1>contact.</h1>
    <p> Are you interested in my work? Drop me an email to <a href="mailto:monika-theresia@kalliscriptum.com">monika-theresia@kalliscriptum.com</a> </p>
    </div>
  </div>
  
</template>

<script setup>
	import { ref, onMounted } from 'vue'


	const german_codes = ["de", "de-AT", "de-BE", "de-CH", "de-DE", "de-IT", "de-LI", "de-LU"]
	const is_german = ref(false)

	onMounted(() => {
		var userLang = navigator.language || navigator.userLanguage; 
		console.log("The language is: " + userLang);
		if ( german_codes.includes(userLang) ) {
			is_german.value = true
		} 
		else {
			is_german.value = false
		} 
	})

</script>

<style scoped>
  #jumbo {
  background-image: url('../assets/website.jpg');
  background-position: center;
  background-size: cover;
  height: 50vh;
  }
  
  .grouptext {
    font-size: 20px;
    color: #2c3e50;
  }
</style>
