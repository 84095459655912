<template>

  <nav class="navbar navbar-expand-lg fixed-top navbar-light bg-light  justify-content-between">
    <div class="container-fluid">
      <div style="max-width: 80%;">
        <a class="navbar-brand" href="#"><img src='./assets/brand.png' style="max-height: 20px; max-width: 90%;"/></a>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse ms-auto" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#about">about.</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#contact">contact.</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <router-view/>

  <div id="footer" class="container-fluid" style="background-color: #cacaca;">
    <div class="container" style="padding-top: 20px;padding-bottom: 50px;">
      <div class="row justify-content-center" style="margin-top: 30px;">
        <div class="col-3">
          <a href="https://www.instagram.com/kalliscriptum/"><i class="fa-brands fa-instagram"></i> instagram </a>
        </div>
        <div class="col-3">
          <a href="" data-bs-toggle="modal" data-bs-target="#legalmodal"> legal </a>
        </div>
        <!--
        <div class="col-3">
          Footer
        </div>
        <div class="col-3">
          Footer
        </div>
        -->
      </div>
    </div>
  </div>
  
  <!-- Modal Legal -->
  <div class="modal fade" id="legalmodal" tabindex="-1" aria-labelledby="legalmodal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body" style="text-align: left;">
          <h3> Impressum </h3>
          Monika-Theresia Mütter<br>
          {Adresse auf Anfrage}<br>
          86825 Bad Wörishofen <br>

          <h3> Kontakt </h3>
          monika-theresia@kalliscriptum.com 
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

a {
  color: #000 !important;
  text-decoration: none !important;
}

#jumbo, #art, #about, #contact, #footer {
   margin-top: 50px;
}
</style>

<script>
export default {
  methods: {
    scrolltocontact() { 
      console.log('scolll')
      var element = this.$refs['contact'];
      var top = element.offsetTop;

      console.log(top)
      
    }
  }
}
</script>
